/* Stars.css */

/* Small stars */
#stars {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 
      719px 1160px #FFF,
      455px 1835px #FFF,
      1245px 973px #FFF,
      1120px 620px #FFF,
      884px 1231px #FFF,
      689px 448px #FFF,
      1334px 75px #FFF,
      1956px 1481px #FFF,
      1805px 133px #FFF,
      1483px 196px #FFF; /* Continue adding up to 700 similar entries */
    animation: animStar 50s linear infinite;
  }
  
  #stars:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 
      719px 1160px #FFF,
      455px 1835px #FFF,
      1245px 973px #FFF,
      1120px 620px #FFF,
      884px 1231px #FFF,
      689px 448px #FFF,
      1334px 75px #FFF,
      1956px 1481px #FFF,
      1805px 133px #FFF,
      1483px 196px #FFF; /* Continue adding up to 700 similar entries */
  }
  
  /* Medium stars */
  #stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 
      1119px 160px #FFF,
      755px 835px #FFF,
      145px 173px #FFF,
      220px 1620px #FFF,
      884px 131px #FFF,
      1689px 1448px #FFF,
      1134px 775px #FFF,
      956px 481px #FFF,
      1505px 1133px #FFF,
      1283px 1696px #FFF; /* Continue adding up to 200 similar entries */
    animation: animStar 100s linear infinite;
  }
  
  #stars2:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 
      1119px 160px #FFF,
      755px 835px #FFF,
      145px 173px #FFF,
      220px 1620px #FFF,
      884px 131px #FFF,
      1689px 1448px #FFF,
      1134px 775px #FFF,
      956px 481px #FFF,
      1505px 1133px #FFF,
      1283px 1696px #FFF; /* Continue adding up to 200 similar entries */
  }
  
  /* Large stars */
  #stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 
      119px 660px #FFF,
      1755px 2835px #FFF,
      945px 1173px #FFF,
      120px 1320px #FFF,
      884px 531px #FFF,
      689px 2448px #FFF,
      334px 775px #FFF,
      156px 481px #FFF,
      1005px 1333px #FFF,
      1283px 196px #FFF; /* Continue adding up to 100 similar entries */
    animation: animStar 150s linear infinite;
  }
  
  #stars3:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 
      119px 660px #FFF,
      1755px 2835px #FFF,
      945px 1173px #FFF,
      120px 1320px #FFF,
      884px 531px #FFF,
      689px 2448px #FFF,
      334px 775px #FFF,
      156px 481px #FFF,
      1005px 1333px #FFF,
      1283px 196px #FFF; /* Continue adding up to 100 similar entries */
  }
  
  @keyframes animStar {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-2000px);
    }
  }
  