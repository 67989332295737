.sidebar {
  width: 200px;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-right: 1px solid rgba(255, 255, 255, 0.18);
  color: white;
  box-sizing: border-box;
}

.sidebar-header {
  font-size: 1.5em;
  margin-bottom: 10px;
  text-align: center;
}

.sidebar-divider {
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin: 10px 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-item {
  margin: 10px 0;
}

.sidebar-item-content {
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  border-radius: 5px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.sidebar-item-content:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.5);
}

.sidebar-link {
  text-decoration: none;
  color: inherit;
}

.telegram-icon {
  margin-right: 5px;
  vertical-align: middle;
}

/* New Styles for the Telegram border and Active Users text */
.sidebar-item-content a {
  display: inline-flex;
  align-items: center;
}

.sidebar-item-content a {
  border-color: blue; /* Add blue border for Telegram section */
}

.sidebar-item-content a:hover {
  border-color: blue;
}

.active-users {
  color: rgb(255, 255, 255); /* Make the active users text color pink */
  border-color: rgb(220, 180, 19);
}

.Telegram-link {
border-color: rgb(8, 160, 184);
}