body, html, #root {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; /* Prevent scrolling */
  font-family: Arial, sans-serif;
}

.app {
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box; /* Ensuring padding is included in the element's total width and height */
}
