/* General styling for the profile page */
.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 95vh; /* Reduced height to fit within the container */
  width: 100%;
  background: rgba(255, 255, 255, 0.2); /* Background with frosted effect */
  backdrop-filter: blur(10px);
  padding: 20px;
  box-sizing: border-box;
  color: white; /* White text to stand out against the frosted background */
  overflow-y: auto; /* Prevent overflow */
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 10px; /* Slight margin to give space */
}

.header h2 {
  font-size: 1.8em;
  margin: 0;
}

.header p {
  font-size: 1.2em;
  margin: 0;
}

/* Content area styling */
.content {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabs {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}

.tabs button {
  padding: 10px 20px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}

.tabs button.active {
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.tabs button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.tab-display {
  flex-grow: 1;
  width: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  overflow-y: auto; /* Allow scroll if content exceeds */
}

/* Specific styling for the tab content */
.tab-content h3 {
  margin-bottom: 10px;
  font-size: 1.5em;
}

.tab-content h4 {
  margin-top: 20px;
  font-size: 1.3em;
  color: #ffcb05; /* Optional: Color to distinguish sections */
}

.tab-content p {
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 20px;
}

.tab-content ol {
  padding-left: 20px;
  list-style: decimal inside;
}

.tab-content li {
  margin-bottom: 10px;
  font-size: 1em;
  line-height: 1.5;
}

.tab-content a {
  color: #00acee; /* Color for links */
  text-decoration: none;
  transition: color 0.3s ease;
}

.tab-content a:hover {
  color: #0088cc; /* Darker blue on hover */
}

/* Data table styling */
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.data-table th, .data-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.data-table th {
  background-color: rgba(255, 255, 255, 0.15);
}

.data-table tr:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Sign Out button styling */
.sign-out-button {
  padding: 10px 20px;
  background-color: rgba(255, 69, 58, 0.8);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px; /* Reduced margin for better spacing */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.sign-out-button:hover {
  background-color: rgba(255, 69, 58, 1);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    text-align: center;
  }

  .tabs {
    flex-direction: column;
    align-items: center;
  }

  .tabs button {
    width: 100%;
    margin-bottom: 10px;
  }

  .tab-display {
    padding: 15px;
  }
}

/* Login and Sign-up form styling */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 20px;
  box-sizing: border-box;
}

.login-form {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px; /* Max width for responsiveness */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
}

.login-form h2 {
  margin-bottom: 20px;
  color: white;
  text-align: center;
}

.login-form .form-group {
  width: 100%; /* Make form group fill the container */
  margin-bottom: 15px;
}

.login-form label {
  display: block;
  margin-bottom: 5px;
  color: white;
}

.login-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
}

.login-form button {
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  width: 100%; /* Make the button fill the width */
}

.login-form button:hover {
  background-color: #0056b3;
}

.login-form .toggle-link {
  margin-top: 15px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  display: block;
  width: 100%;
}

/* Additional styling for form responsiveness */
@media (max-width: 600px) {
  .login-form {
    padding: 20px;
    max-width: 100%;
  }
}

/* Error message styling */
.error-message {
  color: #ff4d4d;
  margin-bottom: 10px;
  text-align: center;
}
