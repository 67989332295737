.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.236);
  padding: 10px;
  box-sizing: border-box;
}

.dashboard-title {
  font-size: 24px;
  color: white;
}

.dashboard-balance-card {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.116);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  border-radius: 5px;
}

.dashboard-middle {
  display: flex;
  flex-direction: row;
  height: 60%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: 10px 0;
  box-sizing: border-box;
}

.main-content {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.main-message-card {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}

.main-message {
  font-size: 2rem;
  animation: grow 0.5s ease-in-out infinite;
  transition: transform 0.1s ease-in-out;
}

.main-message.animate {
  transform: scale(1.1);
}

.countdown {
  font-size: 24px;
  color: white;
  margin-top: 20px;
}

.bet-controls {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  box-sizing: border-box;
}

.bet-button,
.place-bet-button,
.cashout-button {
  padding: 10px 20px;
  background-color: rgba(7, 143, 131, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  cursor: pointer;
  margin: 0 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.bet-button.disabled,
.place-bet-button.disabled,
.cashout-button.disabled {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
  box-shadow: none;
}

.custom-amount-input,
.cashout-amount {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  margin: 0 5px;
  box-sizing: border-box;
  width: 100px;
  border-radius: 5px;
}

.custom-amount-input.disabled,
.cashout-amount.disabled {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
  box-shadow: none;
}

.custom-amount-input::-webkit-outer-spin-button,
.custom-amount-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-amount-input[type='number'] {
  -moz-appearance: textfield;
}

.bottom-sections {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.multiplier-history {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: 10px;
  padding: 10px;
  box-sizing: border-box;
  height: 500px;
  overflow-y: auto;
}

.bet-history {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: 10px;
  padding: 10px;
  box-sizing: border-box;
  height: 500px;
  overflow-y: auto;
}

.bet-history {
  flex: 1;
  overflow-y: auto;
}

.multiplier-history {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
}

.bet-history-header,
.multiplier-history-header {
  font-size: 18px;
  color: white;
  margin-bottom: 0px;
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 0);
}

.bet-history .half-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 95%;
}

.bet-history .half-section .history-item {
  width: 100%;
}

.bet-history .half-section::-webkit-scrollbar {
  display: none;
}

.multiplier-history .half-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
  margin-top: 10px;
}

.multiplier-history .half-section::-webkit-scrollbar {
  display: none;
}


.history-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}

.history-column {
  flex: 1;
  padding: 5px;
}

.user-column {
  text-align: left;
}

.bet-amount-column {
  text-align: center;
}

.cashout-amount-column {
  text-align: right;
}


.history-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: rgba(7, 143, 131, 0.1);
  border: 1px solid rgba(7, 143, 131, 0.3);
  color: white;
  border-radius: 5px;
  margin: 5px 0;
  font-size: 14px;
}

.history-item .history-column {
  flex: 1;
  padding: 5px;
}

.history-item .user-column {
  text-align: left;
}

.history-item .bet-amount-column {
  text-align: center;
}

.history-item .cashout-amount-column {
  text-align: right;
}

.no-history {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  border-radius: 5px;
  margin: 5px;
  font-size: 14px;
  text-align: center;
  width: calc(100% - 20px);
  box-sizing: border-box;
}

.deposit-button,
.withdraw-button {
  padding: 10px 20px;
  background-color: rgba(0, 0, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  cursor: pointer;
  margin: 0 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  position: relative;
  background: #ffffff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.modal h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333333;
}

.modal input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  margin-left: -10px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  font-size: 16px;
}

.modal button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 10px;
}

.modal button:hover {
  background-color: #f0f0f0;
}

.modal .submit-button {
  background-color: #4caf50;
  color: white;
}

.modal .submit-button:hover {
  background-color: #45a049;
}

.modal .cancel-button {
  background-color: #f44336;
  color: white;
}

.modal .cancel-button:hover {
  background-color: #e53935;
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.login-warning {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  transition: opacity 0.5s ease;
}

.tab-button {
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 5px;
}

.tab-button.active {
  background-color: rgba(0, 255, 0, 0.2);
  border-color: rgba(0, 255, 0, 0.3);
}

.tab-button:hover {
  background-color: rgba(0, 255, 0, 0.15);
}

.coming-soon {
  position: relative;
  font-size: 24px;
  color: white;
  background-color: rgba(255, 165, 0, 0.1); /* Light orange background */
  border: 2px dashed rgba(255, 165, 0, 0.5); /* Dashed orange border */
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  animation: fadeIn 1.5s ease-in-out infinite alternate;
  box-shadow: 0 0 15px rgba(255, 165, 0, 0.3);
  margin: 20px 0;
  overflow: hidden;
}

.coming-soon::before {
  content: "⚠️";
  font-size: 5rem;
  color: rgba(255, 0, 0, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: hazardFlash 1.5s ease-in-out infinite alternate;
  z-index: 1; /* Ensure it shows on top */
}

@keyframes hazardFlash {
  0% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.7;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1.05);
  }
}

